import MemberCard from './MemberCard.vue'
import YourMembers from './YourMembers.vue'
import MemberCardBlank from './MemberCardBlank.vue'
import MemberCardState from './MemberCardState.vue'
import YourMembersMini from './YourMembersMini.vue'
import CongressionalMember from './CongressionalMember.vue'
import CongressionalMembersFilter from './CongressionalMembersFilter.vue'
import CongressionalSessionSelector from './CongressionalSessionSelector.vue'
import CongressionalMemberSessionSelector from './CongressionalMemberSessionSelector.vue'

const membersComponents = [
  MemberCard,
  YourMembers,
  MemberCardBlank,
  MemberCardState,
  YourMembersMini,
  CongressionalMember,
  CongressionalMembersFilter,
  CongressionalSessionSelector,
  CongressionalMemberSessionSelector,
]

export default membersComponents
