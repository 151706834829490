import CongressLocator from './CongressLocator.vue'
import MemberSearchResults from './MembersSearchResults.vue'
import MemberComparisonModal from './MemberComparisonModal.vue'
import MemberFiltersContainer from './MemberFiltersContainer.vue'
import MemberSearchResultsRow from './MemberSearchResultsRow.vue'

const congressLocatorComponents = [
  CongressLocator,
  MemberSearchResults,
  MemberComparisonModal,
  MemberFiltersContainer,
  MemberSearchResultsRow,
]

export default congressLocatorComponents
