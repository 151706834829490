import PledgeStats from './PledgeStats.vue'
import PledgeSigner from './PledgeSigner.vue'
import PledgeSigners from './PledgeSigners.vue'

const pledgeComponents = [
  PledgeStats,
  PledgeSigner,
  PledgeSigners,
]

export default pledgeComponents
