import axios from 'axios'
import { assign } from 'lodash'

export const state = () => ({
  votes: [],
  cosponsorships: [],
})

export const actions = {
  /**
   * @param {*} commit
   * @param {object} payload
   * @returns {Promise<void>} void
   */
  async getVotes({ commit }, payload) {
    const params = assign({ perPage: 25 }, payload)

    const { data } = await axios.get('/api/scorecard/votes', { params })

    commit('SET_VOTES', data.data)
  },

  /**
   * @param {*} commit
   * @param {object} payload
   * @returns {Promise<void>} void
   */
  async getCosponsorships({ commit }, payload) {
    const params = assign({ limit: 25 }, payload)

    const { data } = await axios.get('/api/scorecard/cosponsorships', { params })

    commit('SET_COSPONSORSHIPS', data.data)
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {array} votes
   * @constructor
   */
  SET_VOTES: (state, votes) => { state.votes = votes },

  /**
   * @param {object} state
   * @param {array} cosponsorships
   * @constructor
   */
  SET_COSPONSORSHIPS: (state, cosponsorships) => { state.cosponsorships = cosponsorships },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
