import { createApp } from 'vue'
import VueAffix from 'vue-affix'
import VueNl2br from 'vue3-nl2br'
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto'
import VueSocialSharing from 'vue-social-sharing'
import { mapState, mapActions, mapMutations } from 'vuex'
import { svgSpriteDirectivePlugin } from 'vue-svg-sprite'

import { store } from './store'
import components from './components'

// import.meta.glob('~/img/**/*')

const app = createApp({
  computed: {
    ...mapState({
      mobileNav: state => state.ui.mobileNav,
      windowWidth: state => state.ui.windowWidth,
      activeCongressionalSession: state => state.session.activeCongressionalSession,
    }),
  },
  watch: {
    windowWidth() {
      if (this.windowWidth > 992) {
        this.$store.commit('ui/RESET_MOBILE_NAV')
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)

    this.$store.dispatch(
      'session/setActiveCongressionalSession',
      window.activeCongressionalSession,
    )

    this.$store.dispatch('districtState/checkStoredLocation')
  },
  methods: {
    ...mapActions({
      showModal: 'modals/showModal',
    }),
    ...mapMutations({
      toggleMobileNav: 'ui/TOGGLE_MOBILE_NAV',
    }),
    onResize(event) {
      this.$store.commit('ui/SET_WINDOW_WIDTH', event.target.innerWidth)
    },
  },
})

// app.config.compilerOptions.delimiters = ['${', '}']

app.use(store)

app.component('VueNl2br', VueNl2br)

components.forEach((Component) => {
  app.component(Component.name, Component)
})

app.use(VueAffix)
app.use(VueCookies)
app.use(VueSocialSharing)
app.use(VueScrollTo, { offset: -150 })
app.use(svgSpriteDirectivePlugin, { url: '/assets/svg/symbol/svg/sprite.symbol.svg' })

app.mount('#app')

