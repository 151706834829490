import ScorecardSubnav from './ScorecardSubnav.vue'
import PositionIndicator from './PositionIndicator.vue'
import ChangeDistrictForm from './ChangeDistrictForm.vue'
import SelectDistrictForm from './SelectDistrictForm.vue'
import SetDistrictAndState from './SetDistrictAndState.vue'
import ScorecardBreadcrumbs from './ScorecardBreadcrumbs.vue'

const utilityComponents = [
  ScorecardSubnav,
  PositionIndicator,
  ChangeDistrictForm,
  SelectDistrictForm,
  SetDistrictAndState,
  ScorecardBreadcrumbs,
]

export default utilityComponents
