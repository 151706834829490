export const state = () => ({
  openedModal: null,
})

export const actions = {
  /**
   * @param {*} commit
   * @param {string} name
   * @returns void
   */
  showModal({ commit }, name) {
    commit('SET_OPENED_MODAL', name)
  },

  /**
   * @param {*} commit
   * @returns void
   */
  hideModal({ commit }) {
    commit('RESET_OPENED_MODAL')
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {string} name
   * @constructor
   */
  SET_OPENED_MODAL: (state, name) => { state.openedModal = name },

  /**
   * @param {object} state
   * @constructor
   */
  RESET_OPENED_MODAL: (state) => { state.openedModal = null },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
