import MemberScorecard from './MemberScorecard.vue'
import VotingRecordRow from './VotingRecordRow.vue'
import MemberVotingRecord from './MemberVotingRecord.vue'
import MemberCosponsorships from './MemberCosponsorships.vue'
import MemberBillCosponsorshipRow from './MemberBillCosponsorshipRow.vue'
import MemberListCosponsorshipRow from './MemberListCosponsorshipRow.vue'

const memberScorecardComponents = [
  MemberScorecard,
  VotingRecordRow,
  MemberVotingRecord,
  MemberCosponsorships,
  MemberBillCosponsorshipRow,
  MemberListCosponsorshipRow,
]

export default memberScorecardComponents
