import { cloneDeep } from 'lodash'
import VueCookies from 'vue-cookies'

import congressionalSessions from '../../helpers/congressionalSessions'

export const state = () => ({
  chamber: 'Senate',
  activeCongressionalSession: null,
  congressionalSessions: cloneDeep(congressionalSessions),
})

export const getters = {
  /**
   * @param {object} state
   * @returns {object} currentCongressionalSession
   */
  currentCongressionalSession: state => state.congressionalSessions.find(session => session.isCurrent),
}

export const actions = {
  /**
   * @param {object} getters
   * @param {*} commit
   * @param {number} session
   * @returns void
   */
  setActiveCongressionalSession({ getters, commit }, session) {
    const sessionRegex = /^\d+$/
    const activeCongressionalSession = sessionRegex.test(session)
      ? session
      : getters.currentCongressionalSession.id

    commit('SET_ACTIVE_CONGRESSIONAL_SESSION', activeCongressionalSession)

    VueCookies.set('activeCongressionalSession', activeCongressionalSession, 60 * 60 * 24 * 30)
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {number} session
   * @constructor
   */
  SET_ACTIVE_CONGRESSIONAL_SESSION: (state, session) => { state.activeCongressionalSession = session },

  /**
   * @param {object} state
   * @param {string} chamber
   * @constructor
   */
  SET_ACTIVE_CHAMBER: (state, chamber) => { state.chamber = chamber },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
