import Thumb from './Thumb.vue'
import Checked from './Checked.vue'
import Megaphone from './Megaphone.vue'
import SvgLoader from './SvgLoader.vue'
import Unchecked from './Unchecked.vue'
import FaqQuestion from './FaqQuestion.vue'
import SelectArrow from './SelectArrow.vue'
import MagnifyingGlass from './MagnifyingGlass.vue'

const iconComponents = [
  Thumb,
  Checked,
  Megaphone,
  SvgLoader,
  Unchecked,
  SelectArrow,
  FaqQuestion,
  MagnifyingGlass,
]

export default iconComponents
