export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    containerClasses: {
      type: String,
      required: false,
      default: null,
    },
    labelClasses: {
      type: String,
      required: false,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputClasses: {
      type: String,
      required: false,
      default: null,
    },
  },
}
