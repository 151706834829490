export default [
  {
    abbr: 'AL',
    name: 'Alabama',
    districts: [
      '01', '02', '03', '04', '05', '06', '07',
    ],
  },
  {
    abbr: 'AK',
    name: 'Alaska',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'AZ',
    name: 'Arizona',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09',
    ],
  },
  {
    abbr: 'AR',
    name: 'Arkansas',
    districts: [
      '01', '02', '03', '04',
    ],
  },
  {
    abbr: 'CA',
    name: 'California',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53',
    ],
  },
  {
    abbr: 'CO',
    name: 'Colorado',
    districts: [
      '01', '02', '03', '04', '05', '06', '07',
    ],
  },
  {
    abbr: 'CT',
    name: 'Connecticut',
    districts: [
      '01', '02', '03', '04', '05',
    ],
  },
  {
    abbr: 'DE',
    name: 'Delaware',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'DC',
    name: 'District of Columbia',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'FL',
    name: 'Florida',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
    ],
  },
  {
    abbr: 'GA',
    name: 'Georgia',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14',
    ],
  },
  {
    abbr: 'HI',
    name: 'Hawaii',
    districts: [
      '01', '02',
    ],
  },
  {
    abbr: 'ID',
    name: 'Idaho',
    districts: [
      '01', '02',
    ],
  },
  {
    abbr: 'IL',
    name: 'Illinois',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    ],
  },
  {
    abbr: 'IN',
    name: 'Indiana',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09',
    ],
  },
  {
    abbr: 'IA',
    name: 'Iowa',
    districts: [
      '01', '02', '03', '04',
    ],
  },
  {
    abbr: 'KS',
    name: 'Kansas',
    districts: [
      '01', '02', '03', '04',
    ],
  },
  {
    abbr: 'KY',
    name: 'Kentucky',
    districts: [
      '01', '02', '03', '04', '05', '06',
    ],
  },
  {
    abbr: 'LA',
    name: 'Louisiana',
    districts: [
      '01', '02', '03', '04', '05', '06',
    ],
  },
  {
    abbr: 'ME',
    name: 'Maine',
    districts: [
      '01', '02',
    ],
  },
  {
    abbr: 'MD',
    name: 'Maryland',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08',
    ],
  },
  {
    abbr: 'MA',
    name: 'Massachusetts',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09',
    ],
  },
  {
    abbr: 'MI',
    name: 'Michigan',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14',
    ],
  },
  {
    abbr: 'MN',
    name: 'Minnesota',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08',
    ],
  },
  {
    abbr: 'MS',
    name: 'Mississippi',
    districts: [
      '01', '02', '03', '04',
    ],
  },
  {
    abbr: 'MO',
    name: 'Missouri',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08',
    ],
  },
  {
    abbr: 'MT',
    name: 'Montana',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'NE',
    name: 'Nebraska',
    districts: [
      '01', '02', '03',
    ],
  },
  {
    abbr: 'NV',
    name: 'Nevada',
    districts: [
      '01', '02', '03', '04',
    ],
  },
  {
    abbr: 'NH',
    name: 'New Hampshire',
    districts: [
      '01', '02',
    ],
  },
  {
    abbr: 'NJ',
    name: 'New Jersey',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    ],
  },
  {
    abbr: 'NM',
    name: 'New Mexico',
    districts: [
      '01', '02', '03',
    ],
  },
  {
    abbr: 'NY',
    name: 'New York',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
    ],
  },
  {
    abbr: 'NC',
    name: 'North Carolina',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13',
    ],
  },
  {
    abbr: 'ND',
    name: 'North Dakota',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'OH',
    name: 'Ohio',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16',
    ],
  },
  {
    abbr: 'OK',
    name: 'Oklahoma',
    districts: [
      '01', '02', '03', '04', '05',
    ],
  },
  {
    abbr: 'OR',
    name: 'Oregon',
    districts: [
      '01', '02', '03', '04', '05',
    ],
  },
  {
    abbr: 'PA',
    name: 'Pennsylvania',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    ],
  },
  {
    abbr: 'RI',
    name: 'Rhode Island',
    districts: [
      '01', '02',
    ],
  },
  {
    abbr: 'SC',
    name: 'South Carolina',
    districts: [
      '01', '02', '03', '04', '05', '06', '07',
    ],
  },
  {
    abbr: 'SD',
    name: 'South Dakota',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'TN',
    name: 'Tennessee',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09',
    ],
  },
  {
    abbr: 'TX',
    name: 'Texas',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36',
    ],
  },
  {
    abbr: 'UT',
    name: 'Utah',
    districts: [
      '01', '02', '03', '04',
    ],
  },
  {
    abbr: 'VT',
    name: 'Vermont',
    districts: [
      '01',
    ],
  },
  {
    abbr: 'VA',
    name: 'Virginia',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
    ],
  },
  {
    abbr: 'WA',
    name: 'Washington',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
    ],
  },
  {
    abbr: 'WV',
    name: 'West Virginia',
    districts: [
      '01', '02', '03',
    ],
  },
  {
    abbr: 'WI',
    name: 'Wisconsin',
    districts: [
      '01', '02', '03', '04', '05', '06', '07', '08',
    ],
  },
  {
    abbr: 'WY',
    name: 'Wyoming',
    districts: [
      '01',
    ],
  },
]
