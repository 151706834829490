import axios from 'axios'

export const state = () => ({
  most: [],
  least: [],
  yourMembers: [],
})

export const actions = {
  /**
   * @param {*} commit
   * @param {object} rootState
   * @param {number | null} session
   * @returns {Promise<void>} void
   */
  async getScoresForDistrict({ commit, rootState }, session) {
    const { data } = await axios.get('/api/scorecard/scores/district', {
      params: {
        state: rootState.districtState.currentUserState,
        session: session || rootState.session.activeCongressionalSession,
        district: rootState.districtState.currentUserDistrict.substring(2, 4),
      },
    })

    commit('SET_SCORES_FOR_DISTRICT', data.data)
  },

  /**
   * @param {*} commit
   * @param {number} session
   * @returns {Promise<void>} void
   */
  async getLeastConservative({ commit }, session) {
    const { data } = await axios.get('/api/scorecard/scores/leastconservative', {
      params: {
        session,
        chamber: 'both',
      },
    })

    commit('SET_LEAST_CONSERVATIVE', data.data)
  },

  /**
   * @param {*} commit
   * @param {number} session
   * @returns {Promise<void>} void
   */
  async getMostConservative({ commit }, session) {
    const { data } = await axios.get('/api/scorecard/scores/mostconservative', {
      params: {
        session,
        chamber: 'both',
      },
    })

    commit('SET_MOST_CONSERVATIVE', data.data)
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {array} scores
   * @constructor
   */
  SET_SCORES_FOR_DISTRICT: (state, scores) => { state.yourMembers = scores },

  /**
   * @param {object} state
   * @param {array} most
   * @constructor
   */
  SET_MOST_CONSERVATIVE: (state, most) => { state.most = most },

  /**
   * @param {object} state
   * @param {array} least
   * @constructor
   */
  SET_LEAST_CONSERVATIVE: (state, least) => { state.least = least },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
