import { mapState } from 'vuex'

import VStyle from '~/js/components/global/VStyle.vue'

export default {
  components: {
    VStyle,
  },
  data() {
    return {
      averageScore: 0,
    }
  },
  computed: {
    ...mapState({
      activeCongressionalSession: state => state.session.activeCongressionalSession,
    }),
    averageScorePercentage() {
      return `${this.averageScore}%`
    },
    gradientStartColor() {
      switch (true) {
        case (this.averageScore === 0):
          return 'text-red'
        case (this.averageScore < 25):
          return 'text-orange'
        case (this.averageScore < 50):
          return 'text-blue-bright'
        default:
          return 'text-green'
      }
    },
  },
  watch: {
    activeCongressionalSession(session) {
      this.getGroupAverage(session)
    },
  },
}
