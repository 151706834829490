import VueCookies from 'vue-cookies'

export const state = () => ({
  currentUserState: null,
  currentUserDistrict: null,
})

export const actions = {
  /**
   * @param {*} commit
   * @returns void
   */
  checkStoredLocation({ commit }) {
    const district = VueCookies.get('scorecard_district')
    const state = VueCookies.get('scorecard_state')

    if (district) {
      commit('SET_CURRENT_USER_DISTRICT', district)
    }

    if (state) {
      commit('SET_CURRENT_USER_STATE', state)
    }
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {string} userState
   * @constructor
   */
  SET_CURRENT_USER_STATE: (state, userState) => { state.currentUserState = userState },

  /**
   * @param {object} state
   * @param {string} userDistrict
   * @constructor
   */
  SET_CURRENT_USER_DISTRICT: (state, userDistrict) => { state.currentUserDistrict = userDistrict },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
