import CosponsorshipsList from './CosponsorshipsList.vue'
import YourCosponsorships from './YourCosponsorships.vue'
import CosponsorshipDetails from './CosponsorshipDetails.vue'
import BillCosponsorshipRow from './BillCosponsorshipRow.vue'
import CosponsorshipSummary from './CosponsorshipSummary.vue'
import ListCosponsorshipRow from './ListCosponsorshipRow.vue'
import CosponsorshipsListItem from './CosponsorshipsListItem.vue'
import YourCosponsorshipsRow from './YourCosponsorshipsRow.vue'
import CosponsorshipResultTable from './CosponsorshipResultTable.vue'
import CosponsorshipResultTableRow from './CosponsorshipResultTableRow.vue'

const cosponsorshipsComponents = [
  CosponsorshipsList,
  YourCosponsorships,
  BillCosponsorshipRow,
  CosponsorshipDetails,
  CosponsorshipSummary,
  ListCosponsorshipRow,
  YourCosponsorshipsRow,
  CosponsorshipsListItem,
  CosponsorshipResultTable,
  CosponsorshipResultTableRow,
]

export default cosponsorshipsComponents
