export default {
  methods: {
    partyLogo(party) {
      return party === 'D' ? 'DemocratLogo' : 'RepublicanLogo'
    },
    scoreColor(score) {
      switch (true) {
        case score === null:
          return 'text-grey-darkest'
        case score >= 60:
          return 'text-green'
        case score >= 40:
          return 'text-orange'
        case score >= 0:
          return 'text-red'
        default:
          return 'text-grey-darkest'
      }
    },
    scoreString(score) {
      return [null, 'N/A', 'n/a'].includes(score) ? 'N/A' : `${score}%`
    },
    memberStateIcon(state) {
      return `/assets/svg/us2.1.svg#${state}`
    },
    memberPhoto(photo) {
      return photo || '/assets/images/default-profile.png'
    },
    memberFullTitle(member) {
      return `${member.title}. ${member.first_name} ${member.last_name}`
    },
  },
}
