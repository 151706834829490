import AveragePartyScore from './AveragePartyScore.vue'
import AveragePartyScores from './AveragePartyScores.vue'
import AverageChamberPartyScore from './AverageChamberPartyScore.vue'

const averagePartyScoresComponents = [
  AveragePartyScore,
  AveragePartyScores,
  AverageChamberPartyScore,
]

export default averagePartyScoresComponents
