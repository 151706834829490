import votesComponents from './votes'
import membersComponents from './members'
import utilityComponents from './utility'
import mostLeastComponents from './most-least'
import comparisonComponents from './comparison'
import cosponsorshipsComponents from './cosponsorships'
import memberScorecardComponents from './member-scorecard'
import averagePartyScoresComponents from './average-party-scores'

import PartyIcon from './PartyIcon.vue'
import StateScores from './StateScores.vue'
import TakeActionMember from './TakeActionMember.vue'
import TakeActionMembers from './TakeActionMembers.vue'

const scorecardComponents = [
  PartyIcon,
  StateScores,
  TakeActionMember,
  TakeActionMembers,
  ...votesComponents,
  ...membersComponents,
  ...utilityComponents,
  ...mostLeastComponents,
  ...comparisonComponents,
  ...cosponsorshipsComponents,
  ...memberScorecardComponents,
  ...averagePartyScoresComponents,
]

export default scorecardComponents
