import AppTextInput from './AppTextInput.vue'
import AppRadioInput from './AppRadioInput.vue'
import AppSelectInput from './AppSelectInput.vue'
import AppCheckboxInput from './AppCheckboxInput.vue'
import AppTextareaInput from './AppTextareaInput.vue'

const formInputComponents = [
  AppTextInput,
  AppRadioInput,
  AppSelectInput,
  AppCheckboxInput,
  AppTextareaInput,
]

export default formInputComponents
