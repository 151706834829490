export const state = () => ({
  filterState: '',
  filterParty: [],
  filterChamber: [],
  memberQuery: null,
  comparedCongressionalMembers: [],
})

export const getters = {
  /**
   * @param {object} state
   * @returns {number} memberFilterCount
   */
  memberFilterCount: (state) => {
    let count = state.filterChamber.length + state.filterParty.length

    if (state.filterState !== '') {
      count += 1
    }

    return count
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {object} member
   * @constructor
   */
  UPDATE_COMPARED_MEMBERS: (state, member) => {
    if (state.comparedCongressionalMembers.includes(member)) {
      state.comparedCongressionalMembers.splice(
        state.comparedCongressionalMembers.indexOf(member),
        1,
      )
    } else {
      state.comparedCongressionalMembers.push(member)
    }
  },

  /**
   * @param {object} state
   * @param {object} query
   * @constructor
   */
  SET_MEMBER_QUERY: (state, query) => { state.memberQuery = query },

  /**
   * @param {object} state
   * @param {string} filterState
   * @constructor
   */
  SET_FILTER_STATE: (state, filterState) => { state.filterState = filterState },

  /**
   * @param {object} state
   * @param {array} chamber
   * @constructor
   */
  SET_FILTER_CHAMBER: (state, chamber) => { state.filterChamber = chamber },

  /**
   * @param {object} state
   * @param {array} party
   * @constructor
   */
  SET_FILTER_PARTY: (state, party) => { state.filterParty = party },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
