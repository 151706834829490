import { createStore } from 'vuex'

import ui from './modules/ui'
import votes from './modules/votes'
import modals from './modules/modals'
import members from './modules/members'
import session from './modules/session'
import scorecard from './modules/scorecard'
import districtState from './modules/districtState'

export const store = createStore({
  modules: {
    ui,
    votes,
    modals,
    members,
    session,
    scorecard,
    districtState,
  },
})
