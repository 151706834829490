export const state = () => ({
  mobileNav: false,
  windowWidth: null,
})

export const mutations = {
  /**
   * @param {object} state
   * @constructor
   */
  RESET_MOBILE_NAV: (state) => { state.mobileNav = false },

  /**
     * @param state
     * @returns {boolean}
     */
  TOGGLE_MOBILE_NAV: state => (state.mobileNav = !state.mobileNav),

  /**
   * @param {object} state
   * @param {number} width
   * @constructor
   */
  SET_WINDOW_WIDTH: (state, width) => { state.windowWidth = width },
}

export default {
  namespaced: true,
  state,
  mutations,
}
