const sessions = [
  {
    id: 118,
    title: '118th',
    years: '2023/2024',
    isCurrent: true,
  },
  {
    id: 117,
    title: '117th',
    years: '2021/2022',
    isCurrent: false,
  },
  {
    id: 116,
    title: '116th',
    years: '2019/2020',
    isCurrent: false,
  },
  {
    id: 115,
    title: '115th',
    years: '2017/2018',
    isCurrent: false,
  },
  {
    id: 114,
    title: '114th',
    years: '2015/2016',
    isCurrent: false,
  },
  {
    id: 113,
    title: '113th',
    years: '2013/2014',
    isCurrent: false,
  },
  {
    id: 112,
    title: '112th',
    years: '2011/2012',
    isCurrent: false,
  },
]

export default sessions
