import KeyVote from './KeyVote.vue'
import VotesList from './VotesList.vue'
import YourKeyVotes from './YourKeyVotes.vue'
import VotesListItem from './VotesListItem.vue'
import YourKeyVotesRow from './YourKeyVotesRow.vue'
import KeyVoteResultTable from './KeyVoteResultTable.vue'
import KeyVoteResultSummary from './KeyVoteResultSummary.vue'
import KeyVoteResultTableRow from './KeyVoteResultTableRow.vue'

const votesComponents = [
  KeyVote,
  VotesList,
  YourKeyVotes,
  VotesListItem,
  YourKeyVotesRow,
  KeyVoteResultTable,
  KeyVoteResultSummary,
  KeyVoteResultTableRow,
]

export default votesComponents
