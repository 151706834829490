import blogComponents from './blog'
import iconComponents from './icons'
import globalComponents from './global'
import pledgeComponents from './pledge'
import searchComponents from './search'
import tablesComponents from './tables'
import toolkitsComponents from './toolkits'
import scorecardComponents from './scorecard'
import activismCenterComponents from './activism-center'
import congressLocatorComponents from './congress-locator'

const components = [
  ...blogComponents,
  ...iconComponents,
  ...globalComponents,
  ...pledgeComponents,
  ...searchComponents,
  ...tablesComponents,
  ...toolkitsComponents,
  ...scorecardComponents,
  ...activismCenterComponents,
  ...congressLocatorComponents,
]

export default components
